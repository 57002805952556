"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BenchmarkDuration = exports.BenchmarkMetrics = void 0;
exports.BenchmarkMetrics = [{
        type: null,
        filter: null,
        alias: "V_I_answered_rate",
        title: "Answer rate (of offered) (Inbound) (Voice)",
        shortTitle: "Answer Rate Voice Inbound",
        unit: "%",
        description: "Total number of answered calls of all offered calls."
    }, {
        type: null,
        filter: null,
        alias: "V_O_answered_rate",
        title: "Answer rate (of offered) (Outbound) (Voice)",
        shortTitle: "Answer Rate Voice Outbound",
        unit: "%",
        description: "Total number of answered outbound calls of all offered calls."
    }, {
        type: null,
        filter: null,
        alias: "V_I_wait_time_answered_avg",
        title: "Wait time avg. (Inbound) (Voice)",
        shortTitle: "Average Speed to Answer",
        unit: "sec",
        description: "Average amount of time for calls to be answered. Includes the amount of time callers wait in queue."
    }, {
        type: null,
        filter: null,
        alias: "V_I_sla_answered_rate_GTE_20",
        title: "SLA rate (answered) (Inbound) (Voice) GTE 20",
        shortTitle: "Service Level 20 Seconds",
        unit: "%",
        description: "The percentage of calls answered within 20 seconds."
    }, {
        type: null,
        filter: null,
        alias: "V_I_agent_answer_rate",
        title: "Agent answer rate (Answered of Agent offered) (Inbound) (Voice)",
        shortTitle: "Agent Answer Rate Voice",
        unit: "%",
        description: "Total number of calls answered by agent of offered."
    }, {
        type: null,
        filter: null,
        alias: "pause_rate",
        title: "Pause rate (Inbound) (Voice)",
        shortTitle: "Pause Rate",
        unit: "%",
        description: "Percentage of total logged-in time spent in a pause state."
    }, {
        type: null,
        filter: null,
        alias: "V_I_wait_time_no_answer_avg",
        title: "Wait Time Average (Abandoned) (Inbound) (Voice)",
        shortTitle: "Avg Aban Wait Time Voice",
        unit: "sec",
        description: "Average Abandoned Wait Time for Voice Calls measures the duration that inbound callers wait on the line before terminating the call without reaching a representative."
    }, {
        type: null,
        filter: null,
        alias: "M_I_interaction_time_avg",
        title: "Interaction Time Average (E-mail)",
        shortTitle: "Interaction Time Avg Email",
        unit: "sec",
        description: "Average Email Interaction Time represents the average duration that an email interaction tab remains open, divided by the number of email interactions handled/answered."
    }, {
        type: null,
        filter: null,
        alias: "M_I_answered_rate",
        title: "Answer rate (of offered) (Inbound) (E-mail)",
        shortTitle: "Answer Rate E-mail",
        unit: "%",
        description: "Total number of answered emails of all offered emails."
    }, {
        type: null,
        filter: null,
        alias: "C_answered_rate",
        title: "Answer rate (of offered) (Inbound + Outbound) (Chat)",
        shortTitle: "Answer Rate Chat",
        unit: "%",
        description: "Total number of answered chats of all offered chats."
    }, {
        type: null,
        filter: null,
        alias: "V_ready_rate",
        title: "Ready Rate (Voice)",
        shortTitle: "Ready Rate",
        unit: "%",
        description: "The percentage of (total) logged in time that agents have been Idle, in a call or in ACW (not in other pause status). Ready rate (Voice) = Ready time/Total login time * 100"
    }, {
        type: null,
        filter: null,
        alias: "V_utilization_rate",
        title: "Utilization Rate (Voice) ",
        shortTitle: "Utilization Rate",
        unit: "%",
        description: "The percentage of time agents spend handling customer inquiries vs. other idle time. Utilization rate (Voice) = Handling time Voice (inbound + outbound)/Ready time * 100"
    }, {
        type: null,
        filter: null,
        alias: "V_full_utilization_rate",
        title: "Full Utilization Rate (Voice)",
        shortTitle: "Full Utilization Rate",
        unit: "%",
        description: "The percentage of time agents spend handling customer inquiries during logged in time. Full utilization rate (Voice) = Handling time Voice (inbound + outbound)/Total login time * 100"
    }, {
        type: null,
        filter: null,
        alias: "VB_booking_success",
        title: "Booking success",
        shortTitle: "Voicebot Taxi",
        unit: "%",
        description: "Booking success of the calls received in voicebot"
    }];
var BenchmarkDuration;
(function (BenchmarkDuration) {
    BenchmarkDuration["TODAY_LIVE"] = "TODAY_LIVE";
    BenchmarkDuration["LAST_7_DAYS"] = "LAST_7_DAYS";
    BenchmarkDuration["LAST_30_DAYS"] = "LAST_30_DAYS";
    BenchmarkDuration["LAST_90_DAYS"] = "LAST_90_DAYS";
    BenchmarkDuration["LAST_180_DAYS"] = "LAST_180_DAYS";
})(BenchmarkDuration = exports.BenchmarkDuration || (exports.BenchmarkDuration = {}));
;
